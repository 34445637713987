import React from "react"
import Footer from '../components/footer/footer'
import SEO from '../components/utils/SEO'
import { useStaticQuery, graphql } from "gatsby"
import MobileNavbar from "../components/navbar/mobileNavbar"
import {FullHeightWrapper, CoverImage} from '../components/pages/indexWrapper'
import DefaultLayout from "../components/layouts/defaultLayout"
import Img from "gatsby-image"

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query HomeLayout {
      logo : imageSharp(fluid: {originalName: {eq: "logo_x2.png"}}){
        fixed(quality: 100, width:300){
          ...GatsbyImageSharpFixed
        }
      }
      backgroundDesktop : imageSharp(fluid: {originalName: {eq: "home-original.jpg"}}) {
        fluid(quality:100, srcSetBreakpoints:[1920, 2560]) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
      backgroundLaptop : imageSharp(fluid: {originalName: {eq: "home-1024.jpg"}}){
        fluid(quality:100, maxWidth:1440, srcSetBreakpoints:[1024,1440]) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
      backgroundTablet : imageSharp(fluid: {originalName: {eq: "home-768.jpg"}}){
        fluid (maxWidth:1024, srcSetBreakpoints:[768]){
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  `)
  const backgroundSources = [
    {
      ...data.backgroundTablet.fluid,
      media: "(min-width: 769px) and (max-width: 1024px)",
      sizes: "(max-width:1024px) 1024px, 100vw"
    },
    {
      ...data.backgroundLaptop.fluid,
      media: "(min-width:1025px) and (max-width:1440px)",
      sizes: "(max-width:1440px) 1440px, 100vw"
    },
    {
      ...data.backgroundDesktop.fluid,
      media: "(min-width:1441px)",
      sizes: "(min-width:1441px) 100vw, 1441px"
    }
  ]
  
  return (
    <DefaultLayout>
      <SEO title="Acceuil - Tribal Cabane" description="Tribal Cabane est un collectif d'artistes-décorateurs qui s'exprime à l'aide d'éléments issus de la nature pour réaliser des cabanes, décortions, sculptures, décors et ateliers.Nous cultivons et souhaitons partager une créativité intuitive aliée à la nature à l'aide de moyens d'expressions primitifs et simples à mettre en oeuvre."></SEO>
      <MobileNavbar isHomescreen={true} />
      <FullHeightWrapper>
        <div className="cover-image-wrapper">
          <Img className="home-logo" fixed={data.logo.fixed} />
          <div className="cover-image">
            <CoverImage fluid={backgroundSources} />
          </div>
        </div>
        <Footer />
      </FullHeightWrapper>
    </DefaultLayout>

  )
}



export default IndexPage
