import styled from 'styled-components'
import {down, up} from 'styled-breakpoints'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

const CoverImage = styled(Img).attrs({
  className: 'coverImage'
})`
  flex: 1;
  height: 100%;
  top: 0;
  ${down('sm')}{
    margin: 20px;
    min-height: unset;
  }
  
`

const FullHeightWrapper = styled.div`
  ${up('md')}{
    height: 100vh;
    .cover-image-wrapper, .coverImage{
      height: calc(100vh - ${p => p.theme.heights.footer});      
    }
    .cover-image{
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      z-index:0
    }
   
 
    .cover-image-wrapper{
      position: relative;
     .home-logo{
        position: absolute;
        top: 30px;
        left: calc(50% - 150px); 
        right: 50%;
        z-index: 1;
        
      }
      
    }
  }
  ${down('md')}{
    min-height: 100vh;
    
  }
  ${down("sm")}{
    .cover-image-wrapper{
      display: flex;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 100px;
    }
    .cover-image{
      display:none;
    }
  }
  
  display: flex;
  flex-direction: column;
 

`
export {CoverImage, FullHeightWrapper}